window.addEventListener('load', function load(event) {
  console.log('loaded ... ');
  console.log('nooooo');
//   transformSearchBar(document.getElementById('searchText'));
});
// function transformSearchBar(ele) {
//   // ele.onfocus=function() {};
//   document.getElementById('lounge_form').classList.toggle('focused');
//   document.getElementById('search').className='show';
//   document.getElementById('search_tail').classList.toggle('hide');
// }
function checkCharLimit(ele) {
  const inputText = document.getElementById('searchText').value;
  document.getElementById('chars-used').innerHTML=60-inputText.length;
  if (60-inputText.length < 0) {
    document.getElementById('chars-used').classList.add('too-many-chars');
  } else {
    document.getElementById('chars-used').classList.remove('too-many-chars');
  }
}
function validSubmit() {
  const inputText = document.getElementById('searchText').value;

  if (inputText.length > 60) {
    return false;
  }
  return true;
}

searchTerms = 'talkdust';
homePage = true;
